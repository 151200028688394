import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Quote from '@/components/Quote'
import { useContentInProp } from '@/hooks/useContentInProp'
import { MobileFooter, Right } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'

const BecomePage: React.FC = () => {
  const inProp = useContentInProp()
  const { t } = useTranslation()

  const content = {
    pretitle: t('careers.become_super'),
    title: t('careers.become_title'),
    text: t('careers.become_body'),
    links: [
      {
        link: t('careers.become_cta_1'),
        url: '/careers/overview',
        variant: 'all',
      },
      {
        link: t('careers.become_cta_2'),
        url: '/careers/tour',
        variant: 'all',
      },
    ],
  }

  const QuoteComponent = (
    <Quote
      variant="light"
      name={t('careers.become_quote_name')}
      jobTitle={t('careers.become_quote_job')}
      maxWidth="55rem"
      inProp={inProp}
    >
      {t('careers.become_quote_body')}
    </Quote>
  )

  return (
    <>
      <Right />

      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box
                  col={0.6}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  {QuoteComponent}
                </Box>
                <Box col={0.4}>
                  <Text {...content} />
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/careers"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default BecomePage
